import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { useAxios, useUi } from 'hooks';
import * as storage from 'utils/storage';

export const STORAGE_KEY = 'auditoria.declaracao_saude';

export const STATUS = [
  'PENDENTE DE AUDITORIA',
  'EM AUDITORIA',
  'RESPOSTA EM ANALISE',
  'CONCLUIDO',
  'RECUSADO',
  'CANCELADO',
] as const;

export type StatusType = (typeof STATUS)[number];

export type EspecificacaoType = {
  titulo: string;
  portador: boolean;
  grau?: string;
  local?: string;
  tempo?: string;
  tipo?: string;
};

export type PerguntaType = {
  especificacao: EspecificacaoType[];
  especificacaoDinamica: boolean;
  especificacaoManual: string;
  id: string;
  possui: boolean;
  subtitulo: string;
  titulo: string;
};

export type BeneficiarioType = {
  altura: string;
  cpf: string;
  dataNasc: string;
  idBeneficiario: string;
  nome: string;
  perguntas: PerguntaType[];
  peso: string;
  sexo: 'F' | 'M';
  telefone: string;
};

export type FormularioType = {
  beneficiarios: BeneficiarioType[];
  dataPreenchimento: string;
  obsGeral: string;
};

export type StorageType = {
  formulario: FormularioType;
  protocolo: string;
  tipoPreenchimento: string;
  status: StatusType;
  operadora: number;
};

export type DeclaracaoSaudeContextType = {
  formulario?: FormularioType;
  protocolo?: string;
  tipoPreenchimento?: string;
  status: StatusType;
  operadora?: number;
  setDs: Dispatch<SetStateAction<StorageType>>;
  updateStatus: (newStatus: StatusType) => void;
  clearDs: () => void;
};

export const STORAGED_DS = JSON.parse(
  storage.get(STORAGE_KEY) ?? '{}'
) as StorageType;

const DeclaracaoSaudeContext = createContext<DeclaracaoSaudeContextType>(
  {} as DeclaracaoSaudeContextType
);

const DeclaracaoSaudeProvider: React.FC = ({ children }) => {
  const { api } = useAxios();
  const { hideBackdrop, showBackdrop } = useUi();
  const navigate = useNavigate();

  const [ds, setDs] = useState<StorageType>(STORAGED_DS);
  const [fetchingStatus, setFetchingStatus] = useState(false);

  const formulario = useMemo(() => ds.formulario, [ds]);
  const protocolo = useMemo(() => ds.protocolo, [ds]);
  const tipoPreenchimento = useMemo(() => ds.tipoPreenchimento, [ds]);
  const status = useMemo(() => ds.status, [ds]);
  const operadora = useMemo(() => ds.operadora, [ds]);

  const updateStatus = useCallback((newStatus: StatusType): void => {
    setDs(prevState => ({ ...prevState, status: newStatus }));
  }, []);

  const clearDs = useCallback(() => {
    setDs({} as StorageType);
  }, []);

  useEffect(() => {
    storage.set(STORAGE_KEY, JSON.stringify(ds));
  }, [ds]);

  useEffect(() => {
    if (!protocolo) {
      return;
    }

    setFetchingStatus(true);
    api
      .get(
        `${process.env.REACT_APP_MS_AUDITORIA_URL}/protocolo/${protocolo}/status`
      )
      .then(({ data }) => {
        updateStatus(data.status);
        setFetchingStatus(false);
      })
      .catch(() => {
        navigate('/error');
      });
  }, [api, navigate, protocolo, updateStatus]);

  useEffect(() => {
    if (fetchingStatus) {
      showBackdrop();
    } else {
      hideBackdrop();
    }

    return () => {
      hideBackdrop();
    };
  }, [fetchingStatus, hideBackdrop, showBackdrop]);

  return (
    <DeclaracaoSaudeContext.Provider
      value={{
        formulario,
        protocolo,
        tipoPreenchimento,
        status,
        setDs,
        updateStatus,
        clearDs,
        operadora,
      }}
    >
      {children}
    </DeclaracaoSaudeContext.Provider>
  );
};

export { DeclaracaoSaudeContext, DeclaracaoSaudeProvider };
