import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';

import { SolicitarInfoForm, PendenciaItem } from 'components';
import { useAxios } from 'hooks';
import { useParams } from 'react-router-dom';

const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return bytes;
};

const handleOpenPDF = (base64: string): void => {
  const arrayBuffer = base64ToArrayBuffer(base64);
  const file = new Blob([arrayBuffer], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  window.open(fileURL);
};

export type DiseaseProps = {
  id: string;
  doenca: string;
  titulo: string;
  tipo: string;
  tempo: string;
  local: string;
  grau: string;
  especificacaoManual: string;
};

type DiseaseRowProps = {
  disease: DiseaseProps;
  idBeneficiario: string;
  disable?: boolean;
  protocol?: string;
};

type DocumentoProps = {
  id: number;
  tamanho: number;
  documento?: string;
  nome?: string;
  dataInclusao?: string;
};

export type DoencaProps = {
  id: number;
  idDoenca: string;
  especificacaoTitulo: string;
  titulo: string;
  tipo?: string;
  tempo: string;
  local?: string;
  grau?: string;
  idBeneficiario: string;
  protocolo: string;
};

type PerguntaStatusProps = {
  id: number;
  status?: string;
};

export type PerguntaProps = {
  id: number;
  pergunta: string;
  dataInclusao: string;
  idProfissional: number;
  liberarAnexo: boolean;
  status: PerguntaStatusProps;
};

export type RespostaProps = {
  id: number;
  dataResposta?: string;
  resposta?: string;
  dataVisualizacao?: string;
};

export type PendenciaProps = {
  documento: DocumentoProps;
  doenca: DoencaProps;
  pergunta: PerguntaProps;
  protocolo: string;
  resposta: RespostaProps;
  dataExpiracaoLink: string;
};

const DiseaseRow: React.FC<DiseaseRowProps> = ({
  disease,
  idBeneficiario,
  disable,
  protocol,
}) => {
  const [pendencias, setPendencias] = useState<PendenciaProps[]>([]);
  const [openForm, setOpenForm] = useState(false);
  const [showSkeletonPendencia, setShowSkeletonPendencia] = useState(false);
  const { api } = useAxios();
  const { protocolo } = useParams();
  const { id, doenca, titulo, tipo, tempo, local, grau, especificacaoManual } =
    useMemo(() => disease, [disease]);
  const disableSolicitarButton = useMemo(() => {
    const lastItem = [...pendencias].pop();

    return lastItem?.pergunta.id !== 0 && lastItem?.resposta.id === 0;
  }, [pendencias]);

  const perguntaIsEmpty = useMemo(() => {
    const lastItem = [...pendencias].pop();

    if (lastItem === undefined) {
      return true;
    }

    return lastItem?.doenca.id === 0 || lastItem?.pergunta.id === 0;
  }, [pendencias]);

  const respostasNaoVisualizadas = useMemo(
    () =>
      pendencias
        .filter(p => !!p.resposta.id && !p.resposta?.dataVisualizacao)
        .map(p => p.resposta),
    [pendencias]
  );

  const fetchPendencias = useCallback((): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/perguntas/listar-perguntas-resposta-documento`;
    const params = {
      protocolo: protocol ?? protocolo,
      idBeneficiario,
      idDoenca: id,
    };

    setShowSkeletonPendencia(true);

    api
      .get(url, { params })
      .then(({ data }) => {
        setPendencias(data);
      })
      .finally(() => {
        setShowSkeletonPendencia(false);
      });
  }, []);

  const updateResposta = (respostas: RespostaProps[]): void => {
    const url = `${process.env.REACT_APP_MS_AUDITORIA_URL}/respostas`;

    setShowSkeletonPendencia(true);

    const promises = respostas.map(resposta =>
      api.post(`${url}/${resposta.id}/viewed`)
    );

    Promise.all(promises)
      .then(() => {
        fetchPendencias();
      })
      .finally(() => {
        setShowSkeletonPendencia(false);
      });
  };

  useEffect(() => {
    fetchPendencias();
  }, [fetchPendencias]);

  useEffect(() => {
    if (respostasNaoVisualizadas.length > 0) {
      updateResposta(respostasNaoVisualizadas);
    }
  }, [respostasNaoVisualizadas]);

  const handleOpenForm = (): void => {
    setOpenForm(true);
  };

  const handleCloseForm = (): void => {
    setOpenForm(false);
    fetchPendencias();
  };

  return (
    <Box>
      <Box sx={{ marginBottom: 3 }}>
        <Typography>
          <b>Doença preenchida pelo beneficiário:</b> {titulo}
        </Typography>
        <Typography>
          <b>Categoria:</b> {doenca}
        </Typography>
        {tipo && (
          <Typography>
            <b>Tipo:</b> {tipo}
          </Typography>
        )}
        {tempo && (
          <Typography>
            <b>Tempo:</b> {tempo}
          </Typography>
        )}
        {local && (
          <Typography>
            <b>Local:</b> {local}
          </Typography>
        )}
        {grau && (
          <Typography>
            <b>Grau:</b> {grau}
          </Typography>
        )}
        {especificacaoManual && (
          <Typography>
            <b>Detalhes:</b> {especificacaoManual}
          </Typography>
        )}
      </Box>
      <Divider />
      <Box sx={{ marginTop: 3 }}>
        {showSkeletonPendencia && <Skeleton variant="text" />}
        {!showSkeletonPendencia && perguntaIsEmpty ? (
          <Typography>Nenhuma informação solicitada.</Typography>
        ) : (
          <Grid container>
            {pendencias.map(pendencia =>
              pendencia.pergunta.id === 0 ? null : (
                <Grid
                  key={JSON.stringify(pendencia)}
                  item
                  sm={12}
                  md={12}
                  sx={{ mb: 2, '&:last-of-type': { mb: 0 } }}
                >
                  <Box>
                    <PendenciaItem
                      pergunta={pendencia.pergunta}
                      handleClose={fetchPendencias}
                      disabled={disable}
                    />

                    {!!pendencia.resposta?.resposta && (
                      <Typography sx={{ mb: 1 }}>
                        Resposta do titular: {pendencia.resposta.resposta}
                      </Typography>
                    )}
                    {!pendencia.resposta?.resposta &&
                      pendencia.pergunta.status.id === 1 && (
                        <Chip label="Aguardando envio" />
                      )}
                    {!pendencia.resposta?.resposta &&
                      pendencia.pergunta.status.id === 2 && (
                        <Chip label="Aguardando resposta do titular" />
                      )}

                    {pendencia.documento?.documento && (
                      <Tooltip
                        title="Ver documento enviado pelo proponente"
                        placement="top"
                      >
                        <Button
                          type="button"
                          size="small"
                          variant="text"
                          onClick={() =>
                            pendencia.documento?.documento &&
                            handleOpenPDF(pendencia.documento.documento)
                          }
                        >
                          Ver documento
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        )}
      </Box>
      <Box display={disable ? 'none' : ''} sx={{ marginTop: 3 }}>
        <Button
          variant="outlined"
          onClick={handleOpenForm}
          disabled={disableSolicitarButton}
        >
          Solicitar Informações
        </Button>
      </Box>
      <SolicitarInfoForm
        open={openForm}
        handleClose={handleCloseForm}
        disease={disease}
        idBeneficiario={idBeneficiario}
        createNewDoenca={pendencias.length === 0}
      />
    </Box>
  );
};

export default DiseaseRow;
