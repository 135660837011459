import { memo, useMemo } from 'react';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { Speed } from '@mui/icons-material';

type Imc = {
  altura: number;
  peso: number;
  handleClick: () => void;
  notification: any;
};

const ChipImc: React.FC<Imc> = ({
  altura,
  peso,
  handleClick,
  notification,
}) => {
  const imc = useMemo(() => peso / (altura * altura), [altura, peso]);

  const exceededLimit = useMemo(() => imc >= 30, [imc]);

  const imcText = useMemo(() => `${imc.toFixed(1)} kg/m²`, [imc]);

  const imcInfo = useMemo(() => {
    const description =
      handleClick === undefined ? 'IMC' : 'Solicitar Informações do IMC';
    return { description };
  }, []);

  const imcProps = useMemo(() => {
    let hex = '';
    let description = '';

    if (imc < 18.6) {
      hex = '#6eb5e9';
      description = 'Abaixo do peso';
    } else if (imc >= 18.6 && imc < 25) {
      hex = '#7ec394';
      description = 'Peso normal';
    } else if (imc >= 25 && imc < 30) {
      hex = '#f9e352';
      description = 'Sobrepeso';
    } else if (imc >= 30 && imc < 35) {
      hex = '#ffa827';
      description = 'Obesidade I';
    } else if (imc >= 35 && imc < 40) {
      hex = '#fc6f42';
      description = 'Obesidade II';
    } else {
      hex = '#dd4e54';
      description = 'Obesidade III';
    }

    return { hex, description };
  }, [imc]);

  return (
    <>
      <Tooltip title={imcInfo.description}>
        <Button
          onClick={handleClick}
          variant={notification}
          size="small"
          sx={{ height: 24, minWidth: 30 }}
        >
          <Speed fontSize="small" />
        </Button>
      </Tooltip>
      <Tooltip title={imcProps.description}>
        <Stack direction="row" spacing={1}>
          <Typography
            color={exceededLimit ? '#dd4e54' : ''}
            fontWeight={exceededLimit ? 'bold' : 'regular'}
          >
            {imcText}
          </Typography>
        </Stack>
      </Tooltip>
    </>
  );
};

export default memo(ChipImc);
